import React, { useState } from 'react'
import httpClient from '../httpClient'
import { Link } from 'react-router-dom'
import logo from '../assets/images/Stoqup_logo_white.png'

function LoginPage() {

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("") 

  const logInUser = async () => {
    try {
      const data = {email, password}
      const response = await httpClient.post(process.env.REACT_APP_API_URL + "/api/login", data)

      if (response.data.type == "horeca" | response.data.type == "admin") {
        window.location.href = "/home"
        await httpClient.get(process.env.REACT_APP_API_URL + "/api/update/linkedvendors/partners")
      } else setError("Email and/or password not correct")
      
    } catch (error) {
      if (error.response.status == 401) {
        setError("Email and/or password not correct")
      }
    }
  }

  return (
    <div className='login-page'>
      <Link to="/"><img className='stoqup-logo-left-top' src={logo} width={250}/></Link>

      <form className='login-form'>
        <input 
          value={email} 
          onChange={e => setEmail(e.target.value)} 
          placeholder='EMAIL'
        />
        <input 
          value={password}
          onChange={e => setPassword(e.target.value)}
          type='password' 
          placeholder='PASSWORD'
        />

      <div className='error'>
        {error}
      </div>

      <button type='button' className='button-login' onClick={logInUser}>LOG IN</button>
      <div className='login-links'>
        <Link to="/forgotpassword">Forgot password</Link>
        <Link to="/signup">Sign up</Link>
      </div>
      </form>
    </div>
  )
}

export default LoginPage
