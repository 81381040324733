import React, {useState, useEffect} from "react"
import httpClient from "../httpClient"
import { Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { addToCart, subtractFromCart } from "../store/TotalCartAmount"
import "./Product.css"
import NoImageSpirits from "../assets/images/products/No Image Spirits.png"
import ProductCardPlaceholder from "../components/placeholders/ProductCardPlaceholder"


function Product(props) {
  const [user, setUser] = useState({email: "", id: ""})
  const [loading, setLoading] = useState(true)
  const [counter, setCounter] = useState(0)
  const [error, setError] = useState("")

  const dispatch = useDispatch()

  const productID = props.productID

  useEffect(() => {

    const fetchUser = async () => {
      const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/@me?type=")
      setUser(response.data)
    }
    fetchUser()
      .catch( err => {
        setLoading(false)
        setError("unauthorized")
      })
  }, [])

  useEffect(() => {
    setLoading(true)
  
    const loadImage = new Image()
    loadImage.src = props.url ? props.url : NoImageSpirits
  
    const handleLoad = () => {
      setLoading(false)
    }
  
    loadImage.addEventListener('load', handleLoad)
  
    loadImage.addEventListener('error', handleLoad)
  
    return () => {
      loadImage.removeEventListener('load', handleLoad)
      loadImage.removeEventListener('error', handleLoad)
    }
  }, [props.url])


  const addProductToCart = (e) => {
    setCounter(counter + 1)
    dispatch(addToCart())

    e.target.classList.toggle("active")

    const data = {
      productID: productID,
      userID: user.id,
      amount: 1,
      method: "single"
    }
    httpClient.post(process.env.REACT_APP_API_URL + "/api/cart/add", data)
      .then(response => console.log(response.data))
      .catch(error => console.log(error))
        
    setTimeout(() => {
      e.target.classList.toggle("active")
    }, 150);
  }

  const removeProductFromCart = (e) => {
    if (counter <= 0) return 
    setCounter(counter - 1)
    dispatch(subtractFromCart())

    e.target.classList.toggle("active")

    const data = {
      productID: productID,
      userID: user.id,
      amount: 1,
    }
    httpClient.post(process.env.REACT_APP_API_URL + "/api/cart/min", data)
      .then(response => console.log(response.data))
      .catch(error => console.log(error))
    
    setTimeout(() => {
      e.target.classList.toggle("active")
    }, 150);
  }

  const updateCart = (event) => {
    setCounter(event.target.value)

    if(!event.target.value) return

    const data = {
      productID: productID,
      userID: user.id,
      amount: event.target.value,
      method: "update"
    }
    httpClient.post(process.env.REACT_APP_API_URL + "/api/cart/add", data)
    .then(response => console.log(response))
    .catch(error => console.log(error))
  }

  const renderImage = () => {

    let imageUrl = props.url ? props.url : NoImageSpirits
  
    return (
      <img 
        src={imageUrl} 
        alt=""
        onLoad={() => setLoading(false)}
      />
    )
  }

  return (
    <div className="product-container">
      {loading ?
      <ProductCardPlaceholder/>:
      <div id="product-wrapper" className={props.className}>
        {renderImage()}
        <div className={counter == 0 ? "cart hidden" : "cart" }>
          <p>cart:</p>            
          <input className="input-field" value={counter >= 0 ? counter : 0} onChange={updateCart} />
        </div>
        <Link to={"/product/" + props.productID}>
          <div className="product-info">
            <h3>{props.productName}</h3>
            <div className="product-info--details">
              <p className="sub-title">{props.volume}</p>
            </div>
          </div>
        </Link>
        <div className="add-to-cart-overlay">
          <button 
            className="min-button" 
            onClick={removeProductFromCart}
          >
          -</button>
          <button 
            className="plus-button" 
            onClick={addProductToCart}
          >
          +</button>
        </div>
      </div>
      }
    </div>
)}

export default Product
