import React from "react"
import TextPlaceholder from "./TextPlaceholder"

function ProductCardPlaceholder() {
  return (
    <div className="product_card_placeholder__container">
        <div className="placeholder-product-img-card"></div>
        <TextPlaceholder/>
        <TextPlaceholder/>
    </div>
)}

export default ProductCardPlaceholder
