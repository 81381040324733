import React, {useState} from 'react'
import httpClient from '../httpClient'
import { Link } from 'react-router-dom'
import logo from '../assets/images/Stoqup_logo_white.png'

function ForgotPasswordPage() {

  const [email, setEmail] = useState("")
  const [error, setError] = useState("")
  const [message, setMessage] = useState("Enter your email address. If you have an account registered on this email address, you will receive an email with a reset password link.")

  const handleSubmit = async () => {
    const data = {email}
    const response = await httpClient.post(process.env.REACT_APP_API_URL + "/api/forgotpassword?type=app", data)
    console.log(response.data)
    setMessage("Check your email inbox. An email with a reset password link has been send. The link will expire in 60 minutes.")
  }

  return (
    <div className='login-page'>
      <Link to="/"><img className='stoqup-logo-left-top' src={logo} width={250}/></Link>

      <div className='message'>
        {message}
      </div>
      <form className='login-form'>
        <input 
          value={email} 
          onChange={e => setEmail(e.target.value)} 
          placeholder='EMAIL'
        />

      <div className='error'>
        {error}
      </div>

      <button type='button' className='button-login' onClick={handleSubmit}>SEND LINK</button>
      </form>
    </div>
  )
}

export default ForgotPasswordPage
