import React, {useState, useEffect} from "react"
import httpClient from "../httpClient"
import { ToastContainer, toast } from "react-toastify"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded'
import "./PopupPageProducts.css"
import SearchBar from "../components/SearchBar"
import PopupPageAddProduct from "./PopupPageAddProduct"
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'

function PopupPageProducts() {
  const [user, setUser] = useState({email: "", id: ""})
  const [errorMessage, setErrorMessage] = useState("")
  const [selectedProduct, setSelectedProduct] = useState([])
  const [selectedProductID, setSelectedProductID] = useState([])
  const [products, setProducts] = useState([])
  const [filteredProducts, setFilteredProducts] = useState([])
  const [pseudoVendors, setPseudoVendors] = useState([])
  const [selectedPseudoVendor, setSelectedPseudoVendor] = useState("")
  const [linkedProducts, setLinkedProducts] = useState([])
  

  let key = 0

  const fetchUser = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/@me?type=")
    setUser(response.data)
  }
  
  const fetchProducts = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/products/get-all")
    setProducts(response.data)
    setFilteredProducts(response.data)
  }

  const fetchPseudoVendors = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/vendor/get/pseudo?type=names")
    setPseudoVendors(response.data)
  }

  const fetchLinkedProducts = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/get/linked-products")
    setLinkedProducts(response.data)
  }

  useEffect(() => {
    fetchUser()
      .catch( err => {
        console.log(err)
      })

  fetchProducts()
  .catch(err => {
    console.log(err)
  })  

  fetchPseudoVendors()
  .catch(err => {
    console.log(err)      
  })

  fetchLinkedProducts()
  .catch(err => {
    console.log(err)      
  })

  }, [])
  
  const togglePopup = () => {
    document.getElementById("popup-page-products").classList.toggle("active")
  }

  const handleDropdownClickProducts = () => {
    document.getElementById("dropdown-menu-products").classList.toggle("active")
    document.getElementById("dropdown-icon-products").classList.toggle("active")
  }

  const handleDropdownClickVendors = () => {
    document.getElementById("dropdown-menu-vendors--products").classList.toggle("active")
    document.getElementById("dropdown-icon-vendors--products").classList.toggle("active")
  }

  const selectProduct = (e) => {
    const value = e.currentTarget.firstChild.innerHTML
    const valueID = e.currentTarget.firstChild.id
    setSelectedProduct(value)
    setSelectedProductID(valueID)
    handleDropdownClickProducts()
  }

  const selectVendor = (e) => {
    const value = e.target.innerHTML
    setSelectedPseudoVendor(value)
    handleDropdownClickVendors()
  }

  const createNewVendor = () => {
    handleDropdownClickVendors()
    document.getElementById("popup-page-suppliers").classList.toggle("active")
  }
  
  const createNewProduct = () => {
    handleDropdownClickProducts()
    document.getElementById("popup-page-create-products").classList.toggle("active")
  }

  const handleSaveProducts = async () => {
    const data = {
      supplier: selectedPseudoVendor, 
      productID: selectedProductID,
      type: "normal",
    }
    const response = await toast.promise(
      httpClient.post(process.env.REACT_APP_API_URL + "/api/link-product-to-supplier", data),
        {
          pending: `Adding ${selectedProduct}...`,
          success: `${selectedProduct} has been added`,
          error: "Something went wrong"
        }
      )
    console.log(response.data)
    
    setSelectedProduct("")
    setSelectedProductID("")
    setSelectedPseudoVendor("")
    fetchLinkedProducts()
  }

  const handleDeleteProduct = async (e) => {
    const id = e.currentTarget.parentElement.id
    const product = e.currentTarget.parentElement.firstChild.children[1].firstChild.firstChild.innerHTML
    
    const response = await toast.promise(
      httpClient.delete(process.env.REACT_APP_API_URL + "/api/delete/linked-product?id=" + id),
        {
          pending: `Deleting ${product}...`,
          success: `${product} has been deleted`,
          error: "Something went wrong"
        }
    )
    console.log(response.data)

    fetchLinkedProducts()
  }

  return (
    <div>

      <div id="popup-page-products" className="popup-page">
            
        <ToastContainer position="bottom-right"/>

        <div  className="cancel-button" onClick={togglePopup}>
          <p>cancel</p><KeyboardArrowDownIcon/>
        </div>
        
        <h4>PRODUCTS</h4>

        <div className="popup-page-content">
          <h5>Add new product</h5>

            <div className="popup-page-dropdown">
              <div className={selectedProduct == "" ? "dropdown-title2 select-option" : "dropdown-title2"} onClick={handleDropdownClickProducts}>
                <p>{selectedProduct == "" ? "Select product" : selectedProduct}</p><ArrowDropDownIcon  id="dropdown-icon-products"/>
              </div>
              <div id="dropdown-menu-products" className="dropdown-menu">
                <SearchBar
                  searchList = {products}
                  filteredList = {filteredProducts}
                  setFilteredList = {setFilteredProducts}
                  style = "no-border"
                />
                <div className="create-new" onClick={createNewProduct}><p>Add new product</p><AddBoxRoundedIcon/></div>
                {filteredProducts.map(product => {
                  return (
                    <div 
                      className="lists-dropdown-item" 
                      id="lists-dropdown-item" onClick={selectProduct} key={key++}
                    >
                      <p id={product.id}>{product.name}</p><CheckCircleIcon/>
                    </div>
                  )
                })}
              </div>
            </div>
            
            <div className="popup-page-dropdown">
              <div className={selectedPseudoVendor == "" ? "dropdown-title2 select-option" : "dropdown-title2" } onClick={handleDropdownClickVendors}>
                <p>{selectedPseudoVendor == "" ? "Select supplier" : selectedPseudoVendor}</p><ArrowDropDownIcon  id="dropdown-icon-vendors--products"/>
              </div>
              <div id="dropdown-menu-vendors--products" className="dropdown-menu">
                <div className="create-new" onClick={createNewVendor}><p>Create new supplier</p><AddBoxRoundedIcon/></div>
                {
                  pseudoVendors.map(pseudoVendor => {
                    return <p  onClick={selectVendor} key={key++}>{pseudoVendor}</p>
                  })
                }
              </div>
            </div>
          
            <button className="save-button" onClick={handleSaveProducts}>SAVE</button>

            {
              linkedProducts.length != 0 ? <h5>My products</h5> : ""
            }
            {
              linkedProducts.length != 0 ?
              linkedProducts.map(linkedProduct => {
                let image = linkedProduct.productImage
                try {
                  image = require(`../assets/images/products/${linkedProduct.productImage}`)
                } catch (error) {
                  console.log(error)
                  console.log(linkedProduct.productImage)
                }
                return(
                  <div className="product-list--item" key={key++} id={linkedProduct.id}>
                    <div className="product-list--item__details">
                      <img src={image}/>
                      <div className="product-list--item__details---">
                        <div className="flex--horizon--left">
                          <h6>{linkedProduct.product}</h6><p>{linkedProduct.volume}</p>
                        </div>
                        <p>supplier: <b>{linkedProduct.supplier}</b></p>
                      </div>
                    </div>
                    <div className="product-list--item__delete" onClick={handleDeleteProduct}><DeleteOutlineOutlinedIcon/></div>
                  </div>
                )
              })
              : ""
            }
        </div>
      </div>
      <PopupPageAddProduct/>
    </div>
)}

export default PopupPageProducts
