import React, {useState, useEffect} from "react"
import httpClient from "../httpClient"
import Header from "../components/Header"
import Footer from "../components/Footer"
import PopupPageAddSupplier from "../components/PopupPageAddSupplier"
import PopupPageAddProduct from "../components/PopupPageAddProduct"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded'
import { ToastContainer, toast } from "react-toastify"
import "../styling/ReactToastify.css"
import Product from "../components/Product"
import SearchBar from "../components/SearchBar"
import "./OrderListPage.css"

function OrderListPage() {

  const [user, setUser] = useState({email: "", id: ""})
  const [loading, setLoading] = useState(true)
  const [products, setProducts] = useState([])
  const [filteredProducts, setFilteredProducts] = useState([])
  const [orderLists, setOrderLists] = useState([])
  const [orderListsProducts, setOrderListsProducts] = useState([])
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [inputValueNewList, setInputValueNewList] = useState("")
  const [selectedList, setSelectedList] = useState("")
  const [selectedProducts, setSelectedProducts] = useState([])
  const [pseudoVendors, setPseudoVendors] = useState([])
  const [frequentlyOrderedProducts, setFrequentlyOrderedProducts] = useState([])

  let key = 0

  const fetchLists = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/orderlist/get-lists")
    setOrderLists(response.data)
    setLoading(false)
  }

  const fetchUser = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/@me?type=")
    setUser(response.data)
  }

  const fetchProducts = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/products/get-all")
    setProducts(response.data)
    setFilteredProducts(response.data)
  }

  const fetchListProducts = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/orderlist/get-all")
    setOrderListsProducts(response.data)
  }

  const fetchPseudoVendors = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/vendor/get/pseudo?type=names")
    setPseudoVendors(response.data)
  }

  const fetchFrequentlyOrderedProducts = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/orderlist/frequently-ordered")
    setFrequentlyOrderedProducts(response.data)
  }

  useEffect(() => {
    fetchUser()
      .catch( err => {
        console.log(err)
        setError(true)
      })

    fetchProducts()
      .catch(err => {
        console.log(err)
      })  
      
    fetchListProducts()
      .catch(err => {
        console.log(err)
      })
    
    fetchPseudoVendors()
      .catch(err => {
        console.log(err)      
      })
    
    fetchFrequentlyOrderedProducts()
      .catch(err => {
        console.log(err)      
      })

    fetchLists()
      .catch(err => {
        console.log(err)
      setLoading(false)
      })

  }, [])

  const handleCreateListPopup = () => {
    setSelectedList("")
    setErrorMessage("")
    const popupPage = document.getElementById("popup-page-manage-lists")
    popupPage.classList.toggle("active")
  }

  const handleAddProductsPopup = () => {
    setSelectedList("")
    setErrorMessage("")
    const popupPage = document.getElementById("popup-page-add-products")
    popupPage.classList.toggle("active")
  }

  const handleOnChangeNewList = (e) => {
    setInputValueNewList(e.target.value)
  }

  const handleSaveList = async () => {
    setErrorMessage("")
    try {
      const response = await toast.promise (
        httpClient.post(process.env.REACT_APP_API_URL + "/api/orderlist/create?listName=" + inputValueNewList),
        {
          pending: "Adding list...",
          success: `${inputValueNewList} is created`,
          error: "Something went wrong"
        }
      )
      console.log(response.data) 
      setInputValueNewList("")
    } catch (error) {
      if(error.response.status == 409) {
        setErrorMessage("List already exists. Go back and add products to your list or create a list with another name.")
    }
    }

    fetchLists()
  }

  const handleDropdownClickLists = () => {
    document.getElementById("dropdown-menu-lists").classList.toggle("active")
    document.getElementById("dropdown-icon-list").classList.toggle("active")
  }

  const handleDropdownClickLists2 = () => {
    document.getElementById("dropdown-menu-lists2").classList.toggle("active")
    document.getElementById("dropdown-icon-list2").classList.toggle("active")
  }

  const handleDropdownClickProducts = () => {
    document.getElementById("dropdown-menu-products").classList.toggle("active")
    document.getElementById("dropdown-icon-products").classList.toggle("active")
  }

  const selectList = (e) => {
    setSelectedList(e.target.innerHTML)
    handleDropdownClickLists()
  }

  const selectList2 = async (e) => {
    const list = e.target.innerHTML
    setSelectedList(list)
    handleDropdownClickLists2()

    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/orderlist/get-products-from-list?listName=" + list)
    setSelectedProducts(response.data)
  }

  const selectProducts = (e) => {
    const value = e.currentTarget.firstChild.innerHTML
    if(selectedProducts.includes(value)) {
      setSelectedProducts(selectedProducts.filter(item => item !== value))
    } else {
      setSelectedProducts(prevState => [...prevState, value])
    }
  }

  const handleSaveProducts = async () => {
    const data = {list_name: selectedList, products: selectedProducts}
    document.getElementById("dropdown-menu-products").classList.remove("active")
    document.getElementById("dropdown-icon-products").classList.remove("active")

    const response = await toast.promise(
      httpClient.post(process.env.REACT_APP_API_URL + "/api/orderlist/add-products", data),
      {
        pending: "Updating list..",
        success: "List has been updated",
        error: "Something went wrong"
      }
    )
    console.log(response.data)
    
    //clean up state & selections in "select products"
    setSelectedList("")
    setSelectedProducts([])
    const selectedItems = document.getElementsByClassName("lists-dropdown-item")
    for(let i = 0; i < selectedItems.length; i++) {
      selectedItems[i].classList.remove("selected")
    }
    fetchListProducts()
  }

  const handleClickTitle = (e) => {
    e.currentTarget.nextElementSibling.classList.toggle("active")
    e.currentTarget.children[1].classList.toggle("selected")
  }

  const handleDeleteList = async () => {
    setErrorMessage("")
    const response = await toast.promise (
      httpClient.delete(process.env.REACT_APP_API_URL + "/api/orderlist/delete?listName=" + selectedList),
      {
        pending: "Deleting list...",
        success: `${selectedList} is deleted`,
        error: "Something went wrong"
      }
    )
    console.log(response.data)
    fetchLists()
    
    setSelectedList("")
  }

  const createNewProduct = () => {
    handleDropdownClickProducts()
    document.getElementById("popup-page-create-products").classList.toggle("active")
  }

  const handleCreateProductsPopup = () => {
    setSelectedList("")
    setErrorMessage("")
    const popupPage = document.getElementById("popup-page-create-products")
    popupPage.classList.toggle("active")
  }

  return (
    <div className="body">
      {error ? window.location.href = "/login" : ""}

      <Header />

      <div className="body-orderlist">

      <ToastContainer position="bottom-right"/>

        <div className="actions-orderlist">
          <button onClick={handleCreateListPopup}>Create list</button>
          <button onClick={handleAddProductsPopup}>Add products to list</button>
        </div>

        <div className="order-list-products">

        <div className="list">
          <div className="list-title" onClick={handleClickTitle}>
            <div>Frequently ordered</div>
            <KeyboardArrowDownIcon/>
          </div>
          <div className="list-products" id="list-products">
            {
              frequentlyOrderedProducts.length == 0 ?
              <p>You have no orders placed yet. This section will show your 10 most ordered products.</p> :
              frequentlyOrderedProducts.map(product => {
                return (
                  <Product
                  key = {product[4]}
                  url = {product[2]}
                  productID = {product[4]}
                  productName = {product[0]}
                  productPrice = {product[3] == 0 ? "N/A" : product[3]}
                  className = "product-wrapper"
                  volume = {product[5]}
                  />
                )
              })
            }
          </div>

        </div>

          {
          orderLists.map(list => {
            return (
              <div className="list" key={key++}>
                <div className="list-title" onClick={handleClickTitle}>
                  <div>{list}</div>
                  <KeyboardArrowDownIcon/>
                </div>
                <div className="list-products" id="list-products">
                  {
                  orderListsProducts[list] ?
                    orderListsProducts[list].map(product => {
                      return (
                        <Product 
                          key = {product.id}
                          url = {product.direct_link}
                          productID = {product.id}
                          productName = {product.name}
                          productPrice = {product.price == 0 ? "N/A" : product.price}
                          className = "product-wrapper"
                          volume = {product.volume}
                        />
                      )
                    }) : ""
                  }
                </div>
              </div>
            )
          })
          }
        </div>

      </div>

      <div id="popup-page-manage-lists" className="popup-page">
        <div  className="cancel-button" onClick={handleCreateListPopup}>
          <p>cancel</p><KeyboardArrowDownIcon/>
        </div>
        <h4>CREATE/DELETE LISTS</h4>
        <div className="popup-page-input-field">
          <input id="new-list" type="text" placeholder="New list name" maxLength="25" value={inputValueNewList} onChange={handleOnChangeNewList}></input>
        </div>
        <button className="save-button" onClick={handleSaveList}>CREATE LIST</button>

        <div className="popup-page-dropdown">
          <div className={selectedList == "" ? "dropdown-title select-option" : "dropdown-title" } onClick={handleDropdownClickLists}>
            <p>{selectedList == "" ? "Select list" : selectedList}</p><ArrowDropDownIcon id="dropdown-icon-list"/>
          </div>
          <div id="dropdown-menu-lists" className="dropdown-menu">
            {orderLists.map(list => {
              return <p onClick={selectList} key={key++}>{list}</p>
            })}
          </div>
        </div>
        <button className="save-button" onClick={handleDeleteList}>DELETE LIST</button>
        <div className="error">{errorMessage}</div>
      </div>

      <div id="popup-page-add-products" className="popup-page">
        <div  className="cancel-button" onClick={handleAddProductsPopup}>
          <p>cancel</p><KeyboardArrowDownIcon/>
        </div>
        <h4>ADD/REMOVE PRODUCTS FROM LIST</h4>
        <div className="popup-page-dropdown">
          <div className={selectedList == "" ? "dropdown-title select-option" : "dropdown-title" } onClick={handleDropdownClickLists2}>
            <p>{selectedList == "" ? "Select list" : selectedList}</p><ArrowDropDownIcon id="dropdown-icon-list2"/>
          </div>
          <div id="dropdown-menu-lists2" className="dropdown-menu">
            {orderLists.map(list => {
              return <p onClick={selectList2} key={key++}>{list}</p>
            })}
          </div>
        </div>

        <div className="popup-page-dropdown">
          <div className={selectedProducts == "" ? "dropdown-title2 select-option" : "dropdown-title2" } onClick={handleDropdownClickProducts}>
            <p>Select products</p><ArrowDropDownIcon  id="dropdown-icon-products"/>
          </div>
          <div id="dropdown-menu-products" className="dropdown-menu">
            <SearchBar
              searchList = {products}
              filteredList = {filteredProducts}
              setFilteredList = {setFilteredProducts}
              style = "no-border"
            />
            <div className="create-new" onClick={createNewProduct}><p>Add new product</p><AddBoxRoundedIcon/></div>
            {filteredProducts.map(product => {
              return (
                <div 
                  className={selectedProducts.includes(product.name) ? "lists-dropdown-item selected" : "lists-dropdown-item"} 
                  id="lists-dropdown-item" onClick={selectProducts} key={key++}
                >
                  <p>{product.name}</p><CheckCircleIcon/>
                </div>
              )
            })}
          </div>
        </div>
        <button className="save-button" onClick={handleSaveProducts}>SAVE</button>
        <div className="error">{errorMessage}</div>
      </div>

      <PopupPageAddProduct/>

      <PopupPageAddSupplier
        cleanUp = {true}
      />
    
      <Footer/>
    </div>
  )
}

export default OrderListPage
