import { React, useState } from "react"
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import "./SearchBar.css"

function SearchBar(props) {

  const [searchWord, setSearchWord] = useState("")

  const handleSearch = e => {
    setSearchWord(e.target.value)
    const word = searchWord.toLowerCase()
    const filter = props.searchList.filter(product => {
      return product.name.toLowerCase().includes(word)
    })
    !searchWord ? props.setFilteredList([]) : props.setFilteredList(filter)
  }

  const clearInput = () => {
    props.setFilteredList([])
    setSearchWord("")
    props.setFilteredList([])
  }

  return (
    <div className="search">
      <div className={"search-bar " + props.style}>
        <input onChange={handleSearch} type="text" placeholder="Search..." value={searchWord}/>
        <div className={"search-icon " + props.style}>
          {props.filteredList.length == props.searchList.length ? <SearchIcon/> : <ClearIcon id="clear-icon" onClick={clearInput}/>}
        </div>
      </div>
    </div>
)}

export default SearchBar
