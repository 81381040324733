import React, {useState, useEffect} from "react"
import httpClient from "../httpClient"
import Header from "../components/Header"
import Footer from "../components/Footer"
import LogoutButton from "../components/LogoutButton"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Link } from "react-router-dom"

function AccountPage() {

  const [user, setUser] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [orders, setOrders] = useState([])

  let key = 0

  useEffect(() => {
    const fetchData = async () => {
      const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/@me?type=")
      setUser(response.data)
      setLoading(false)
    }
    fetchData()
      .catch( err => {
        setLoading(false)
        setError(true)
      })
    
      const fetchOrders = async () => {
        const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/orders/get")
        setOrders(response.data)
      }
      fetchOrders()

  }, [])

  const handleClickDetails = (e) => {
    e.currentTarget.querySelector("svg").classList.toggle("selected")
    e.currentTarget.children[1].classList.toggle("active")
  }

  return (
    <div className="body">


      {error ? window.location.href = "/login" : ""}
      <Header/>
      <div className="body-account">
        <div className="account-page-nav">
          <div className="nav-items">
              <Link to="/account"><button>PROFILE</button></Link>
              <Link to="/account/orders"><button className="selected">ORDERS</button></Link>
              <Link to="/account/settings"><button>SETTINGS</button></Link>
          </div>
          <LogoutButton />
        </div>

        <div className="account-orders-wrapper">
          <div className="titles">
            <p className="title-1">ID</p>
            <p className="title-2">Total</p>
            <p className="title-3">Status</p>
            <p className="title-4"></p>
          </div>
          {
            orders.map(order => 
              <div className="order" key={order.id} onClick={handleClickDetails}>
                <div className="order-details">
                  <p className="details-clmn-1">{order.id}</p>
                  <p className="details-clmn-2">€{order.total}</p>
                  <p className="details-clmn-3">{order.status}</p>
                  <KeyboardArrowDownIcon/>
                </div>
                <div className="order-products"> 
                {order.products.map(product =>
                  <div className="product-line" key={key++}>
                    <p className="products-clmn-1">{product.quantity}</p>
                    <p className="products-clmn-2">{product.product}</p>
                    <p className="products-clmn-3">{product.vendor}</p>
                  </div>
                )}
                </div>
              </div>
            )
          }
        </div>

      </div>
      <Footer/>
    </div>
)}

export default AccountPage
