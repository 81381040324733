import React, {useState, useEffect} from "react"
import httpClient from "../httpClient"
import Header from "../components/Header"
import Footer from "../components/Footer"
import Product from "../components/Product"

function HomePage() {

  const [user, setUser] = useState({email: "", id: ""})
  const [products, setProducts] = useState([])
  const [loadingProducts, setLoadingProducts] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
      const fetchUser = async () => {
        const responseUser = await httpClient.get(process.env.REACT_APP_API_URL + "/api/@me?type=")
        setUser(responseUser.data)
      }
      fetchUser()
        .catch( err => {
          console.log(err)
          setError(true)
        })
      
      const fetchProducts = async () => {
        const responseProducts = await httpClient.get(process.env.REACT_APP_API_URL + "/api/products/get-partners")
        setProducts(responseProducts.data)
        setLoadingProducts(false)
      }
      fetchProducts()
        .catch(err => {
          console.log(err)
          setLoadingProducts(false)
          setError(true)
        })

  }, [])

  return (
    <div className="body">
      {error ? window.location.href = "/login" : ""}

    <Header />
      <div className="body-home">
        {products.map(item => 
          <Product 
            key={item.id}
            url= {item.direct_link}
            productID = {item.id}
            productName={item.name}
            productPrice={item.price}
            className = "product-wrapper"
            loading = {loadingProducts}
            volume = {item.volume}
          />
        )}
      </div>
    
      <Footer/>
    </div>
  )
}

export default HomePage

