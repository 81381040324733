import React, {useState, useEffect} from "react"
import httpClient from "../httpClient"
import Header from "../components/Header"
import Footer from "../components/Footer"
import LogoutButton from "../components/LogoutButton"
import { Link } from "react-router-dom"
import SupplierIcon from "../assets/images/icons/Supplier.png"
import ProductsIcon from "../assets/images/icons/Products.png"
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import "./AccountPageSettings.css"
import PopupPageAddSupplier from "../components/PopupPageAddSupplier"
import PopupPageProducts from "../components/PopupPageProducts"

function AccountPage() {

  const [user, setUser] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
      const fetchData = async () => {
        const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/@me?type=")
        setUser(response.data)
        setLoading(false)
      }
      fetchData()
        .catch( err => {
          setLoading(false)
          setError(true)
        })

  }, [])

  const handleOnClick_Setting_item = (settingItem) => {
    const item = document.getElementById("popup-page-" + settingItem)
    item.classList.toggle("active")
  }

  return (
    <div className="body">


      {error ? window.location.href = "/login" : ""}
      <Header/>
      <div className="body-account">
        <div className="account-page-nav">
          <div className="nav-items">
              <Link to="/account"><button>PROFILE</button></Link>
              <Link to="/account/orders"><button>ORDERS</button></Link>
              <Link to="/account/settings"><button className="selected">SETTINGS</button></Link>
          </div>
          <LogoutButton />
        </div>
        <div className="account-settings-wrapper">
            <div className="account__settings--item">
              <img src={SupplierIcon} />
              <p onClick={() => handleOnClick_Setting_item("suppliers")}>My Suppliers</p>
              <ArrowForwardIosOutlinedIcon/>
            </div>

            <div className="account__settings--item">
              <img src={ProductsIcon} />
              <p onClick={() => handleOnClick_Setting_item("products")}>My Products</p>
              <ArrowForwardIosOutlinedIcon/>
            </div>
        </div>
      
        <PopupPageAddSupplier
          cleanUp = {false}
          supplierList = {true}
        />

        <PopupPageProducts/>

      </div>
      <Footer/>
    </div>
)}

export default AccountPage
