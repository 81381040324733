import { BrowserRouter, Routes, Route } from "react-router-dom"
import './index.css'

import WelcomePage from "./pages/WelcomePage"
import LoginPage from "./pages/LoginPage"
import SignupPage from "./pages/SignupPage"
import HomePage from "./pages/HomePage"
import AccountPage from "./pages/AccountPage"
import AccountPageOrders from "./pages/AccountPageOrders"
import AccountPageSettings from "./pages/AccountPageSettings"
import ProductPage from "./pages/ProductPage"
import CartPage from "./pages/CartPage"
import SearchPage from "./pages/SearchPage"
import OrderListPage from "./pages/OrderListPage"
import ForgotPasswordPage from "./pages/ForgotPasswordPage"
import ResetPasswordPage from "./pages/ResetPasswordPage"


function App() {
  return (

    <div className="app">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<WelcomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/account" element={<AccountPage />} />
          <Route path="/account/orders" element={<AccountPageOrders />} />
          <Route path="/account/settings" element={<AccountPageSettings />} />
          <Route path="/product/:productID" element={<ProductPage />} />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/orderlist" element={<OrderListPage />} />
          <Route path="/reset/:token" element={<ResetPasswordPage />} />
        </Routes>
      </BrowserRouter>
    </div>

  );
}

export default App
