import React, {useState, useEffect} from "react"
import httpClient from "../httpClient"
import Header from "../components/Header"
import Footer from "../components/Footer"
import LogoutButton from "../components/LogoutButton"
import { Link } from "react-router-dom"
import PopupError from "../components/PopupError"
import { ToastContainer, toast } from "react-toastify"
import "../styling/ReactToastify.css"
import noImage from '../assets/images/no_image_found.png'

function AccountPage() {

  const [user, setUser] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
      const fetchData = async () => {
        const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/@me?type=full")
        setUser(response.data)
        setLoading(false)
      }
      fetchData()
        .catch( err => {
          setLoading(false)
          setError(true)
        })

  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()

    const popup = document.getElementById('popup-overlay-error')

    const validation = /[A-Z][0-9]{4}\.[0-9]{3}\.[0-9]{3}/g
    const VATNumber = document.getElementById("VAT_number").value
    const businessName = document.getElementById("business_name").value
    const firstName = document.getElementById("first_name").value
    const lastName = document.getElementById("last_name").value
    const email = document.getElementById("email").value
    const companyName = document.getElementById("company_name").value
    const invAddStreet = document.getElementById("invoice_address_street").value
    const invAddNr = document.getElementById("invoice_address_nr").value
    const invAddPc = document.getElementById("invoice_address_pc").value
    const invAddPlace = document.getElementById("invoice_address_place").value
    const invEmail = document.getElementById("invoice_email").value
    const delAddStreet = document.getElementById("delivery_address_street").value
    const delAddNr = document.getElementById("delivery_address_nr").value
    const delAddPc = document.getElementById("delivery_address_pc").value
    const delAddPlace = document.getElementById("delivery_address_place").value
    
    if(!businessName || !firstName || !lastName || !email 
      || !companyName || !invAddStreet || !invAddNr || !invAddPc
      || !invAddPlace || !invEmail || !delAddStreet || !delAddNr
      || !delAddPc || !delAddPlace) {
        popup.classList.add('active')
        return
      }

    if(!validation.test(VATNumber)) {
      document.getElementById("VAT-validation-error").classList.add('active')
      popup.classList.add('active')
      return
    }

    document.getElementById("VAT-validation-error").classList.remove('active')

    const data = {}

    const elements = Array.from(e.target).slice(1)
    elements.forEach((item) => {
      data[item.id] = item.value
    })

    const response = await toast.promise(
      httpClient.post(process.env.REACT_APP_API_URL + "/api/@me/update", data),
      {
        pending: "updating profile..",
        success: "Profile update success!",
        error: "Something went wrong"
      })
  }

  return (
    <div className="body">

      {error ? window.location.href = "/login" : ""}
      <Header/>
      <div className="body-account">
        <PopupError
            message="We've founds some errors. Please check if everything is fill out and in the correct format."
          />
        <ToastContainer position="bottom-right"/>

        <div className="account-page-nav">
          <div className="nav-items">
              <Link to="/account"><button className="selected">PROFILE</button></Link>
              <Link to="/account/orders"><button>ORDERS</button></Link>
              <Link to="/account/settings"><button>SETTINGS</button></Link>
          </div>
          <LogoutButton />
        </div>

        <div className="account-profile-wrapper">
          <div className="profile-picture">
            <div className="profile-picture-border"></div>
            <img src={noImage}></img>
            <h3>{user.business_name}</h3>
          </div>

          <form id="account-form" onSubmit={handleSubmit}>
            <button className="save-button" type="submit">SAVE</button>
            <div className="account-field-block">
              <p>Account</p>
              <input type="text" id="business_name" placeholder={user.business_name ? user.business_name : "Business Name" } defaultValue={user.business_name ? user.business_name : ""}/>
              <input type="text" id="first_name" placeholder={user.first_name ? user.first_name : "First Name" } defaultValue={user.first_name ? user.first_name : ""}/>
              <input type="text" id="last_name" placeholder={user.last_name ? user.last_name : "Last Name" } defaultValue={user.last_name ? user.last_name : ""}/>
              <input type="email" id="email" placeholder={user.email ? user.email : "Email" } defaultValue={user.email ? user.email : ""}/>
            </div>
            <div className="account-field-block">
              <p>Invoicing</p>
              <input type="text" id="company_name" placeholder={user.company_name ? user.company_name : "Company Name" } defaultValue={user.company_name ? user.company_name : ""}/>
              <input type="text" id="VAT_number" placeholder={user.VAT_number ? user.VAT_number : "VAT Number (BE00.000.000)" } defaultValue={user.VAT_number ? user.VAT_number : ""}/>
              <p id="VAT-validation-error" className="VAT-validation-error">Please enter in correct format: BE0000.000.000</p>
              <div className="input-box-2-hrz-right">
                <input type="text" id="invoice_address_street" placeholder={user.invoice_address_street ? user.invoice_address_street : "Invoice Address Street" } defaultValue={user.invoice_address_street ? user.invoice_address_street : ""}/>
                <input type="number" id="invoice_address_nr" placeholder={user.invoice_address_nr ? user.invoice_address_nr : "Nr" } defaultValue={user.invoice_address_nr ? user.invoice_address_nr : ""}/>
              </div>
              <div className="input-box-2-hrz-left">
                <input type="number" id="invoice_address_pc" placeholder={user.invoice_address_pc ? user.invoice_address_pc : "PC" } defaultValue={user.invoice_address_pc ? user.invoice_address_pc : ""}/>
                <input type="text" id="invoice_address_place" placeholder={user.invoice_address_place ? user.invoice_address_place : "Invoice Address Place" } defaultValue={user.invoice_address_place ? user.invoice_address_place : ""}/>
              </div>
              <input type="email" id="invoice_email" placeholder={user.invoice_email ? user.invoice_email : "Invoice Email" } defaultValue={user.invoice_email ? user.invoice_email : ""}/>
            </div>
            <div className="account-field-block">
              <p>Delivery</p>
              <div className="input-box-2-hrz-right">
                <input type="text" id="delivery_address_street" placeholder={user.delivery_address_street ? user.delivery_address_street : "Delivery Address Street" } defaultValue={user.delivery_address_street ? user.delivery_address_street : ""}/>
                <input type="number" id="delivery_address_nr" placeholder={user.delivery_address_nr ? user.delivery_address_nr : "Nr" } defaultValue={user.delivery_address_nr ? user.delivery_address_nr : ""}/>
              </div>
              <div className="input-box-2-hrz-left">
                <input type="number" id="delivery_address_pc" placeholder={user.delivery_address_pc ? user.delivery_address_pc : "PC" } defaultValue={user.delivery_address_pc ? user.delivery_address_pc : ""}/>
                <input type="text" id="delivery_address_place" placeholder={user.delivery_address_place ? user.delivery_address_place : "Delivery Address Place" } defaultValue={user.delivery_address_place ? user.delivery_address_place : ""}/>
              </div>
            </div>
          </form>

        </div>
      </div>
      <Footer/>

    </div>
)}

export default AccountPage
