import React, {useState} from 'react'
import httpClient from '../httpClient'
import { Link } from 'react-router-dom'
import logo from '../assets/images/Stoqup_logo_white.png'

function SignupPage() {

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")

  const registerUser = async () => {
    try {
      const type = "horeca"
      const data = {email, password, type}
      await httpClient.post(process.env.REACT_APP_API_URL + "/api/register", data)

      window.location.href = "/home"
      await httpClient.get(process.env.REACT_APP_API_URL + "/api/update/linkedvendors/partners")
    } catch (error) {
      if (error.response.status == 409) {
        setError("User with this email address already exists. Please login or signup with different email address.")
      }
    }

  }

  return (
    <div className='login-page'>
      <Link to="/"><img className='stoqup-logo-left-top' src={logo} width={250}/></Link>

      <form className='login-form'>
        <input 
          value={email} 
          onChange={e => setEmail(e.target.value)} 
          placeholder='EMAIL'
        />
        <input 
          value={password}
          onChange={e => setPassword(e.target.value)}
          type='password' 
          placeholder='PASSWORD'
        />

      <div className='error'>
        {error}
      </div>

      <button type='button' className='button-login' onClick={registerUser}>SIGN UP</button>
      </form>
    </div>
  )
}

export default SignupPage
