import React, {useState, useEffect} from "react"
import httpClient from "../httpClient"
import { ToastContainer, toast } from "react-toastify"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import "./PopupPageAddSupplier.css"
import PopupError from "../components/PopupError"

function OverflowAddSupplier(props) {

  const [pseudoVendors, setPseudoVendors] = useState([])
  const [errorMessage, setErrorMessage] = useState("")
  const [newSupplierName, setNewSupplierName] = useState("")
  const [inputValueSupplierEmail, setInputValueSupplierEmail] = useState("")
  const [vendorEmails, setVendorEmails] = useState([])
  const [targetInputElementID, setTargetInputElementID] = useState("")

  let key = 0

  const fetchPseudoVendors = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/vendor/get/pseudo?type=full")
    setPseudoVendors(response.data)
  }

  useEffect(() => {
    fetchPseudoVendors()
    .catch(err => {
      console.log(err)      
    })

  }, [])
  
  const handleOnChangeSupplierEmail = (e) => {
    setInputValueSupplierEmail(e.target.value)
  }

  const handleOnChangeNewSupplierInput = (e) => {
    setNewSupplierName(e.target.value)
  }

  const togglePopup = () => {
    document.getElementById("popup-page-suppliers").classList.toggle("active")
  }

  const handleSaveSupplier = async () => {
    setErrorMessage("")

    const popup = document.getElementById('popup-overlay')
    const emailValidation = /[a-z]{1,50}@[a-z]{1,50}\.[a-z]{2,6}/g

    if(!emailValidation.test(inputValueSupplierEmail)) {
      setErrorMessage("Email not recognised. Please enter valid email address.")
      return
    }

    try {
      const data = {vendorName: newSupplierName, vendorEmail: inputValueSupplierEmail}
      const response = await toast.promise (
        httpClient.post(process.env.REACT_APP_API_URL + "/api/vendor/create/pseudo", data),
        {
          pending: `Adding ${newSupplierName}...`,
          success: `${newSupplierName} has been added`,
          error: "Something went wrong"
        }
      )
      console.log(response.data)
    } catch (error) {
      setErrorMessage(error.response.data)
    }


    setNewSupplierName("")
    setInputValueSupplierEmail("")
    fetchPseudoVendors()
    if(props.cleanUp) togglePopup()
  }

  const handleDeleteVendor = async (e) => {
    const vendorID = e.currentTarget.parentElement.id
    const vendorName = e.currentTarget.parentElement.firstChild.firstChild.innerHTML
    const response = await toast.promise (
      httpClient.delete(process.env.REACT_APP_API_URL + "/api/vendor/delete/pseudo?vendorID=" + vendorID),
          {
            pending: `Deleting ${vendorName}...`,
            success: `${vendorName} has been deleted`,
            error: "Something went wrong"
          }
        )
        console.log(response.data)
    
    fetchPseudoVendors()
  }

  const handleOnChangeEmail = (e) => {
    const index = e.currentTarget.id
    pseudoVendors[index].email = e.target.value
  }

  const selectInputField = () => {
    const vendorEmail = document.getElementById(targetInputElementID)
    vendorEmail.select()
  }

  const handleUpdateVendor = (e) => {
    const vendorEmailInput = e.currentTarget.parentElement.firstChild.children[1]
    setTargetInputElementID(String(vendorEmailInput.id))
    vendorEmailInput.select()
  }

  const updateVendorEmail = async (e) => {
    const vendorName = e.target.previousSibling.innerHTML
    const vendorEmail = e.target.value
    const data = {vendorName: vendorName, vendorEmail: vendorEmail}

    const popup = document.getElementById('popup-overlay')
    const emailValidation = /[a-z]{1,50}@[a-z]{1,50}\.[a-z]{2,6}/g

    if(!emailValidation.test(vendorEmail)) {
      popup.classList.add("active")
      return
    }

    const response = await toast.promise (
      httpClient.post(process.env.REACT_APP_API_URL + "/api/vendor/update/pseudo", data),
          {
            pending: `Updating ${vendorName}...`,
            success: `${vendorName} has been updated`,
            error: "Something went wrong"
          }
        )
        console.log(response.data)
    
    fetchPseudoVendors()
  }

  return (
    <div id="popup-page-suppliers" className="popup-page-suppliers">
      <PopupError
        message = "Email address cannot be empty or is not correct. Please try again."
        closeAction = {selectInputField}
      />
      
      <ToastContainer position="bottom-right"/>

      <div  className="cancel-button" onClick={togglePopup}>
        <p>cancel</p><KeyboardArrowDownIcon/>
      </div>
      <h4>MY SUPPLIERS</h4>

      <div className="popup-page-content">
        <h5>Create new supplier</h5>
        <div className="popup-page-input-field-suppliers">
          <input id="new-supplier-name" type="text" placeholder="Enter supplier name" maxLength="25" value={newSupplierName} onChange={handleOnChangeNewSupplierInput}></input>
        </div>
        <div className="popup-page-input-field-suppliers">
          <input id="new-supplier-email" type="text" placeholder="Enter supplier email" maxLength="40" value={inputValueSupplierEmail} onChange={handleOnChangeSupplierEmail}></input>
        </div>
        <div className="error-settings-suppliers">{errorMessage}</div>
        <button className="save-button-suppliers" onClick={handleSaveSupplier}>SAVE</button>
        {
          props.supplierList ? <h5>My suppliers</h5> : ""
        }
        {
          props.supplierList ? 
            pseudoVendors.length != 0 ?
              pseudoVendors.map(pseudoVendor => {
                return (
                  <div className="pseudo-vendor-list--item" key={key++} id={pseudoVendor.id}>
                    <div className="pseudo-vendor-list--item__details">
                      <h6>{pseudoVendor.name}</h6>
                      <input
                        placeholder={pseudoVendor.email}
                        value={vendorEmails[key]} 
                        onChange={handleOnChangeEmail}
                        onBlur={updateVendorEmail} 
                        id={key}
                        >
                      </input>
                      <div id="pseudo-vendor-list--item__details--input_overlay"></div>
                    </div>
                    <div className="pseudo-vendor-list--item__edit" onClick={handleUpdateVendor}><ModeEditOutlineOutlinedIcon/></div>
                    <div className="pseudo-vendor-list--item__delete" onClick={handleDeleteVendor}><DeleteOutlineOutlinedIcon/></div>
                  </div>
                )
              })
            : <p className="p--small-center-light">No vendors to show. Create your first vendor by using the form above.</p>
          : ""
        }
    </div>
  </div>
)}

export default OverflowAddSupplier
