import React from "react"
import { Link } from "react-router-dom"
import Search from '../assets/images/Search.png'
import List from '../assets/images/List.png'
import Shop from '../assets/images/Shop.png'
import Account from '../assets/images/Account.png'

function Footer() {
  return (
   <div className="footer">
        <Link to="/search"><img src={Search}/></Link>
        <Link to="/home"><img src={Shop}/></Link>
        <Link to="/orderlist"><img src={List}/></Link>
        <Link to="/account"><img src={Account}/></Link>
   </div>
)}

export default Footer
