import React from "react"

function Popup(props) {

  const closePopup = () => {
    const popup = document.getElementById('popup-overlay')
    popup.classList.remove('active')
  }

  const actionPopup = (e) => {
      e.preventDefault()
      window.location.href=props.linkTo
    }

  return (
    <div className="popup">
      <div className="overlay" id="popup-overlay">
        <div className="popup-box">
          <div className="popup-message" dangerouslySetInnerHTML={{__html: props.message}}></div>
          <div className="popup-btns">
            <button className="popup-btn-1" onClick={closePopup}>{props.button1}</button>
            <button onClick={actionPopup} className="popup-btn-2">{props.button2}</button>
          </div>
        </div>
      </div>
    </div>
)}

export default Popup
