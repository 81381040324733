import React, { useState, useEffect } from "react"
import httpClient from "../httpClient"
import { Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import TotalCartAmount, { setCartState } from "../store/TotalCartAmount"
import logo from '../assets/images/Stoqup_logo_black.png'
import cart from '../assets/images/Cart.png'

function Header() {

  const dispatch = useDispatch()

  useEffect( () => {

    const fetchCartItems = async () => {
      const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/cart/getquantity?productID=all")
      dispatch(setCartState(response.data.product_amount_in_cart))
    }
    fetchCartItems()
  }, [])

  const cart_amount = useSelector(state => state.TotalCartAmount.value)

  return (
   <div className="header">
      <div className="header-img">
        <Link to="/home"><img id="header-img" src={logo}/></Link>
      </div>
      <div id="header-cart">
        <Link to="/cart">
        <img src={cart}/>
        {
          cart_amount == 0 ? <div> </div> :
          <div id="cart-amount">{cart_amount}</div>
        }
        </Link>
      </div>  
   </div>
)}

export default Header
