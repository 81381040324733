import React, {useState, useEffect} from "react"
import httpClient from "../httpClient"
import { useSelector, useDispatch } from "react-redux"
import { changeState } from "../store/NeedsRefresh"
import { addToCart, subtractFromCart } from "../store/TotalCartAmount"

function AddToCartButton(props) {

  const [user, setUser] = useState({email: "", id: ""})
  const [loading, setLoading] = useState([true, true, true])
  const [counter, setCounter] = useState(0)
  const [error, setError] = useState("")

  const dispatch = useDispatch()

  const productID = props.productID
  
  useEffect(() => {

    const fetchUser = async () => {
      const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/@me?type=")
      setUser(response.data)
      setLoading(prevLoading => {
        const updatedLoading = [...prevLoading]
        updatedLoading[0] = false
        return updatedLoading
      })
    }
    fetchUser()
      .catch( err => {
        setLoading(false)
        setError("unauthorized")
      })

    const fetchCart = async () => {
      const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/cart/getquantity?productID=" + productID)
      setCounter(response.data.product_amount_in_cart)
      setLoading(prevLoading => {
        const updatedLoading = [...prevLoading]
        updatedLoading[2] = false
        return updatedLoading
      })
    }
    fetchCart()
  }, [])


  const addProductToCart = () => {
    setCounter(counter + 1)
    dispatch(addToCart())
    dispatch(changeState(true))

    const data = {
      productID: productID,
      userID: user.id,
      amount: 1,
      method: "single"
    }
    httpClient.post(process.env.REACT_APP_API_URL + "/api/cart/add", data)
      .then(response => console.log(response.data))
      .catch(error => console.log(error))
  }

  const removeProductFromCart = () => {
    if (counter <= 0) return 
    
    setCounter(counter - 1)
    dispatch(subtractFromCart())
    dispatch(changeState(true))

    const data = {
      productID: productID,
      userID: user.id,
      amount: 1,
    }
    httpClient.post(process.env.REACT_APP_API_URL + "/api/cart/min", data)
      .then(response => console.log(response.data))
      .catch(error => console.log(error))

  }

  const updateCart = (event) => {
    setCounter(event.target.value)
    dispatch(changeState(true))

    if(!event.target.value) return

    const data = {
      productID: productID,
      userID: user.id,
      amount: event.target.value,
      method: "update"
    }
    httpClient.post(process.env.REACT_APP_API_URL + "/api/cart/add", data)
    .then(response => console.log(response))
    .catch(error => console.log(error))
  }

  return (
   <div className="add-to-cart-button">
    <button 
      className="min-button" 
      onClick={removeProductFromCart}
    >-</button>
    <input 
      className="input-field" 
      value={counter >= 0 ? counter : 0} 
      onChange={updateCart} 
    ></input>
    <button 
      className="plus-button" 
      onClick={addProductToCart}
    >+</button>
   </div>
)}

export default AddToCartButton
