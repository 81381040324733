import React from "react"

function ProductPlaceholder() {
  return (

    <div className="placeholder-product-img"></div>

)}

export default ProductPlaceholder
