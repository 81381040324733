import { createSlice } from "@reduxjs/toolkit"

export const NeedsRefreshSlice = createSlice({
    name: "needsRefresh",
    initialState: false,
    reducers: {
       changeState: (state, action) => {
        return state = action.payload
    }
  }
})

export const { changeState } = NeedsRefreshSlice.actions
export default NeedsRefreshSlice.reducer