import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/images/Stoqup_logo_black.png'

function WelcomePage() {
  return (
    <div className='welcome-page'>
        <div>
            <img src={logo} alt='stoqup logo black' width={300}/>  
        </div>
        <div>
            <Link to={"/login"}><button className='button-login'>LOG IN</button></Link>
        </div>
    </div>
  )
}

export default WelcomePage