import React from "react"

function PopupError(props) {

  const closePopup = () => {
    const popup = document.getElementById('popup-overlay-error')
    popup.classList.remove('active')
    if (props.closeAction != undefined) props.closeAction()
  }

  return (
    <div>
      <div className="overlay" id="popup-overlay-error">
        <div className="popup-error">
          <div className="popup-message" dangerouslySetInnerHTML={{__html: props.message}}></div>
          <button className="popup-btn-1" onClick={closePopup}>ok</button>
        </div>
      </div>
    </div>
)}

export default PopupError
