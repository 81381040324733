import React, {useState, useEffect} from "react"
import httpClient from "../httpClient"
import AddToCartButton from "./AddToCartButton"
import NoImageSpirits from "../assets/images/products/No Image Spirits.png"
import "./CartItem.css"

function CartItem(props) {

  const CartItemSupplier = () => {

    const handleOnclickVendor = () => {
      document.getElementById("popup-page-link-supplier").classList.toggle("active")
      props.setSelectedSupplier(props.vendor)
      props.setSelectedProduct(props.title)
      props.setSelectedProductID(props.productID)
    }
    
    return (
      <p 
        className={props.vendor == "" ? "cart-item-supplier-empty" : "cart-item-supplier"}
        onClick={handleOnclickVendor}
      >
        {props.vendor == ""? "Select a vendor" : props.vendor}
      </p>
    )
  }

  const deleteItem = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/cart/deleteItem?productID=" + props.productID)
      .then(() => window.location.reload())
    console.log(response.data)
  }

  console.log(props.url)

  const renderImage = () => {

    let imageUrl = props.url ? props.url : NoImageSpirits
  
    return (
      <img 
        src={imageUrl} 
        alt=""
      />
    )
  }

  return (
   <div className="cart-item-wrapper">
    {renderImage()}
    <p className="cart-item-title">{props.title}</p>
    <p className="cart-item-volume">{props.volume}</p>
    <CartItemSupplier/>
    <AddToCartButton
      productID={props.productID}
    />
    <button className="cart-item-delete-btn" onClick={deleteItem}>&times;</button>
  </div>
)}

export default CartItem
