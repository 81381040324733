import React, {useState, useEffect} from "react"
import httpClient from "../httpClient"
import Header from "../components/Header"
import Footer from "../components/Footer"
import Product from "../components/Product"
import SearchBar from "../components/SearchBar"

function HomePage() {

  const [user, setUser] = useState({email: "", id: ""})
  const [products, setProducts] = useState([])
  const [filteredProducts, setFilteredProducts] = useState([])
  const [loadCounter, setLoadCounter] = useState(20)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
      const fetchData = async () => {
        const responseUser = await httpClient.get(process.env.REACT_APP_API_URL + "/api/@me?type=")
        setUser(responseUser.data)
        setLoading(false)
      }
      fetchData()
        .catch( err => {
          console.log(err)
          setLoading(false)
          setError(true)
        })
      
      const fetchProducts = async () => {
        const responseProducts = await httpClient.get(process.env.REACT_APP_API_URL + "/api/products/get-all")
        setProducts(responseProducts.data)
        setLoading(false)
      }
      fetchProducts()
        .catch(err => {
          console.log(err)
          setLoading(false)
          setError(true)
        })

  }, [])

  const handleLoadMoreImages = () => {
    setLoadCounter(loadCounter * 2)
  }

  return (
    <div className="body">
      {error ? window.location.href = "/login" : ""}

      <Header />

        <div className="body-search">
          <SearchBar 
            searchList = {products}
            filteredList = {filteredProducts}
            setFilteredList = {setFilteredProducts}
            style = ""
          />
          <div className="products-search">
          {filteredProducts.slice(0,loadCounter).map(item => 
            <Product 
              key={item.id}
              url= {item.direct_link}
              productID = {item.id}
              productName = {item.name}
              productPrice = {item.price}
              className = "product-wrapper"
              volume = {item.volume}
            />
          )}
          </div>
          {
            filteredProducts.length <= loadCounter ? "" :
            <p onClick={handleLoadMoreImages}>Load more products...</p>
          }

        </div>
    
      <Footer/>
    </div>
  )
}

export default HomePage

