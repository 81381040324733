import React, {useState, useEffect} from "react"
import httpClient from "../httpClient"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import "./PopupPageLinkSupplier.css"

function PopupPageLinkSupplier(props) {

  const [pseudoVendors, setPseudoVendors] = useState([])  
  const [isPartner, setIsPartner] = useState(false)
  const [isPro, setIsPro] = useState(false)
  const [partnerVendorName, setPartnerVendorName] = useState(null)
  const [partnerVendorID, setPartnerVendorID] = useState(null)
  
  let key = 0

  const fetchPseudoVendors = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/vendor/get/pseudo?type=full")
    setPseudoVendors(response.data)
  }

  const checkIfProductIsPartner = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/product/is_partner?productID=" + props.productID )
    if (response.data.is_partner) {
      setIsPartner(true)
      setPartnerVendorName(response.data.vendor_name)
      setPartnerVendorID(response.data.vendor_id)
    } else {
      setIsPartner(false)
    }
  }

  const checkIfProductIsPro = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/product/is_pro?productID=" + props.productID )
    response.data.is_pro ? setIsPro(true) : setIsPro(false)
  }
  
  useEffect(() => {
    checkIfProductIsPartner()
    checkIfProductIsPro()
  }, [props.productID])
  
  useEffect(() => {
    fetchPseudoVendors()
    .catch(err => {
      console.log(err)      
    }) 
  }, [])

  const togglePopup = () => {
    document.getElementById("popup-page-link-supplier").classList.toggle("active")
  }

  const handleOnClickSupplier = async (e) => {
    const data = {
      vendorID: null,
      supplier: e.currentTarget.innerHTML,
      productID: props.productID,
      type: "normal"
    }
    const response = await httpClient.post(process.env.REACT_APP_API_URL + "/api/link-product-to-supplier", data)
    console.log(response.data)

    togglePopup()
    props.fetchCart()
  }

  const handleOnClickStoqup = async () => {
    const data = {
      vendorID: null,
      supplier: "",
      productID: props.productID,
      type: "stoqup",
    }
    const response = await httpClient.post(process.env.REACT_APP_API_URL + "/api/link-product-to-supplier", data)
    console.log(response.data)

    togglePopup()
    props.fetchCart()
  }

  const handleOnClickPartner = async () => {
    const data = {
      vendorID: partnerVendorID,
      supplier: "",
      productID: props.productID,
      type: "partner",
    }
    const response = await httpClient.post(process.env.REACT_APP_API_URL + "/api/link-product-to-supplier", data)
    console.log(response.data)

    togglePopup()
    props.fetchCart()
  }
  
  return (
    <div id="popup-page-link-supplier" className="popup-page">

      <div  className="cancel-button" onClick={togglePopup}>
        <p>cancel</p><KeyboardArrowDownIcon/>
      </div>
      <h4>SELECT SUPPLIER</h4>
      <h5>{`Who will supply ${props.product}?`}</h5>
      {/* {isPro ? <div className="pseudo-vendor-list--item" onClick={handleOnClickStoqup}>Stoqup</div> : ""} */}
      {isPartner ? <div className="pseudo-vendor-list--item" onClick={handleOnClickPartner}>{partnerVendorName}</div> : ""}
      {
        pseudoVendors.map(pseudoVendor => {
          return (
            <div 
              key={key++}
              id={key}
              onClick={handleOnClickSupplier}
              className="pseudo-vendor-list--item"
            >
              {pseudoVendor.name}
            </div>
          )
        })
      }

  </div>
)}

export default PopupPageLinkSupplier
