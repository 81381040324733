import { createSlice } from "@reduxjs/toolkit"

const initialState = { value: 0 }

export const TotalCartAmountSlice = createSlice({
    name: "TotalCartAmount",
    initialState,
    reducers: {
      addToCart: (state) => {
        state.value++
      },
      subtractFromCart: (state) => {
        state.value--
      },
      setCartState: (state, action) => {
        state.value = action.payload
      },
  },
})

export const { addToCart, subtractFromCart, setCartState } = TotalCartAmountSlice.actions
export default TotalCartAmountSlice.reducer