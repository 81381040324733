import React, {useState, useEffect} from "react"
import httpClient from "../httpClient"
import { ToastContainer, toast } from "react-toastify"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded'
import "./PopupPageAddProduct.css"

function PopupPageAddProduct() {

  const [pseudoVendors, setPseudoVendors] = useState([])
  const [errorMessage, setErrorMessage] = useState("")
  const [inputValueProductName, setInputValueProductName] = useState("") 
  const [inputValueProductVolume, setInputValueProductVolume] = useState("") 
  const [selectedPseudoVendor, setSelectedPseudoVendor] = useState("")

  let key = 0

  const fetchPseudoVendors = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/vendor/get/pseudo?type=names")
    setPseudoVendors(response.data)
  }

  useEffect(() => {
    fetchPseudoVendors()
    .catch(err => {
      console.log(err)      
    })    
  }, [])

  const handleCreateProductsPopup = () => {
    const popupPage = document.getElementById("popup-page-create-products")
    popupPage.classList.toggle("active")
  }

  const handleOnChangeNewProduct = (e) => {
    setInputValueProductName(e.target.value)
  }

  const handleOnChangeNewVolume = (e) => {
    setInputValueProductVolume(e.target.value)
  }

  const handleDropdownClickVendors = () => {
    document.getElementById("dropdown-menu-vendors").classList.toggle("active")
    document.getElementById("dropdown-icon-vendors").classList.toggle("active")
  }

  const createNewVendor = () => {
    document.getElementById("popup-page-suppliers").classList.toggle("active")
  }

  const selectVendor = (e) => {
    const value = e.target.innerHTML
    setSelectedPseudoVendor(value)
    handleDropdownClickVendors()
  }

  const handleSavePseudoProduct = async () => {
    const data = {pseudoProductName: inputValueProductName, pseudoVendorName: selectedPseudoVendor, pseudoProductVolume: inputValueProductVolume}

    try {
      const response = await toast.promise (
        httpClient.post(process.env.REACT_APP_API_URL + "/api/product/create/pseudo", data), 
        {
          pending: "Adding product...",
          success: `Product has been added`,
          error: "Something went wrong"
        }
      )
    } catch (error) {
      console.log(error.response.data)
    }

    setInputValueProductName("")
    setSelectedPseudoVendor("")
    setInputValueProductVolume("")
  }



  return (
    <div id="popup-page-create-products" className="popup-page">
      <div  className="cancel-button" onClick={handleCreateProductsPopup}>
        <p>cancel</p><KeyboardArrowDownIcon/>
      </div>
      <h4>ADD PRODUCTS</h4>
      <div className="popup-page-input-field">
        <input id="new-product-name" type="text" placeholder="Enter product name" maxLength="25" value={inputValueProductName} onChange={handleOnChangeNewProduct}></input>
      </div>

      <div className="popup-page-input-field">
        <input id="new-product-volume" type="text" placeholder="Enter product volume (ex. 50cl, 70cl, ...)" maxLength="25" value={inputValueProductVolume} onChange={handleOnChangeNewVolume}></input>
      </div>

      <div className="popup-page-dropdown">
        <div className={selectedPseudoVendor == "" ? "dropdown-title2 select-option" : "dropdown-title2" } onClick={handleDropdownClickVendors}>
          <p>{selectedPseudoVendor == "" ? "Select supplier" : selectedPseudoVendor}</p><ArrowDropDownIcon  id="dropdown-icon-vendors"/>
        </div>
        <div id="dropdown-menu-vendors" className="dropdown-menu">
          <div className="create-new" onClick={createNewVendor}><p>Create new supplier</p><AddBoxRoundedIcon/></div>
          {
            pseudoVendors.map(pseudoVendor => {
              return <p  onClick={selectVendor} key={key++}>{pseudoVendor}</p>
            })
          }
        </div>
      </div>
      <button className="save-button" onClick={handleSavePseudoProduct}>SAVE</button>

      <div className="error">{errorMessage}</div>
    </div>

)}

export default PopupPageAddProduct
