import React, {useState} from 'react'
import httpClient from '../httpClient'
import { Link, useParams } from 'react-router-dom'
import logo from '../assets/images/Stoqup_logo_white.png'

function ResetPasswordPage() {
  const { token } = useParams()

  const [password, setPassword] = useState("")
  const [confirmedPassword, setConfirmedPassword] = useState("")
  const [error, setError] = useState("")

  const resetPassword = async () => {
    if(password === confirmedPassword) {
      try {
        const data = {password}
        const response = await httpClient.post(process.env.REACT_APP_API_URL + "/api/reset?token=" + token, data)
        console.log(response.data)
        window.location.href = "/login"
      } catch (error) {
        if (error.response.status == 409) {
          setError("Reset password link expired")
        }
      }
    } else {
      setError("Passwords don't match.")
    }
  }

  return (
    <div className='login-page'>
      <Link to="/"><img className='stoqup-logo-left-top' src={logo} width={250}/></Link>

      <form className='login-form'>
        <input 
          value={password} 
          onChange={e => setPassword(e.target.value)}
          type='password' 
          placeholder='PASSWORD'
        />
        <input 
          value={confirmedPassword}
          onChange={e => setConfirmedPassword(e.target.value)}
          type='password' 
          placeholder='CONFIRM PASSWORD'
        />

      <div className='error'>
        {error}
      </div>

      <button type='button' className='button-login' onClick={resetPassword}>RESET PASSWORD</button>
      </form>
    </div>
  )
}

export default ResetPasswordPage
