import React, {useState, useEffect} from "react"
import Header from "../components/Header"
import Footer from "../components/Footer"
import CartItem from "../components/CartItem"
import httpClient from "../httpClient"
import { ToastContainer, toast } from "react-toastify"
import "../styling/ReactToastify.css"
import Popup from "../components/Popup"
import { useSelector, useDispatch } from "react-redux"
import { changeState } from "../store/NeedsRefresh"
import PopupPageLinkSupplier from "../components/PopupPageLinkSupplier"
import PopupError from "../components/PopupError"

function CartPage() {

  const [user, setUser] = useState({})
  const [cartItems, setCartItems] = useState([])
  const [loading, setLoading] = useState([true, true, true])
  const [total, setTotal] = useState(0)
  const [error, setError] = useState("")
  const [selectedProduct, setSelectedProduct] = useState("")
  const [selectedSupplier, setSelectedSupplier] = useState("")
  const [selectedProductID, setSelectedProductID] = useState("")
  

  const dispatch = useDispatch()

  const needsRefresh = useSelector(state => state.NeedsRefresh)

  const fetchUser = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/@me?type=full")
    setUser(response.data)
  }

  const fetchCart = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/cart/get")
    setCartItems(response.data)
  }

  const fetchTotal = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/shoppingsession/total")
    setTotal(response.data.total)
  }
  
  useEffect(() => {
    dispatch(changeState(false))

    fetchUser()
      .catch(() => setError("unauthorized"))

    fetchCart()
      .catch( err => console.log(err))
    
    fetchTotal()
      .catch( err => console.log(err))
  }, [])

  const requiredFields = ["VAT_number", "business_name", "email", "delivery_address_street", "delivery_address_nr", "delivery_address_pc", "delivery_address_place", "invoice_address_street", "invoice_address_nr", "invoice_address_pc", "invoice_address_place"]
  const profileIsComplete = requiredFields.every(key => user[key] !== null && user[key] !== undefined)

  const handleOnClick = () => {
    if(needsRefresh) {
      window.location.reload()
    } else {
      sendOrder()
      .catch(err => console.log(err))
    }
    dispatch(changeState(true))
  }

  const sendOrder = async () => {

    const suppliers = document.querySelectorAll(".cart-item-supplier-empty")
    if (suppliers.length > 0) {
      const popup = document.getElementById('popup-overlay-error')
      popup.classList.add('active')
      return
    }

    if (!profileIsComplete) {
      const popup = document.getElementById('popup-overlay')
      popup.classList.add('active')
      return
    }

    const response = await toast.promise(
      httpClient.get(process.env.REACT_APP_API_URL + "/api/order/send"),
      {
        pending: "Sending order to suppliers..",
        success: "Order send to suppliers",
        error: "Something went wrong"
      })
      console.log(response.data)
    }

  return (
   <div className="body">
    <PopupPageLinkSupplier
      productID={selectedProductID}
      product={selectedProduct}
      fetchCart={fetchCart}
    />
    {error ? window.location.href = "/login" : ""}
    <Header />
    <div className="body-cart">
      <ToastContainer position="bottom-right"/>
      <Popup
        message="We need some more information from you first.<br/><br/>Go to your account to complete your profile."
        button1="cancel"
        button2="go to profile"
        linkTo="/account"
      />
      <PopupError
        message="Not all products have a vendor assigned. Click on 'Select a vendor' and assign a vendor to your products."

      />

      {cartItems.length == 0 ? 
        <div className="no-items-in-cart">No items in cart</div> :
        <div className="total-and-order">
          <button onClick={handleOnClick}>{needsRefresh ? "REFRESH" : "SEND ORDER"}</button>
        </div>}
      
      {cartItems.map(item => 
        <CartItem
          productID={item.id}
          key={item.id}
          url={item.direct_link}
          title={item.name}
          price={item.price}
          vendor={item.vendor}
          volume={item.volume}
          setSelectedProduct={setSelectedProduct}
          setSelectedSupplier={setSelectedSupplier}
          setSelectedProductID={setSelectedProductID}
        />
      )}
    </div>
    <Footer />
    </div>
)}

export default CartPage
