import React, {useState, useEffect} from "react"
import { useParams } from 'react-router-dom'
import httpClient from "../httpClient"
import Header from "../components/Header"
import Footer from "../components/Footer"
import AddToCartButton from "../components/AddToCartButton"
import DOMPurify from "dompurify" // this is for savely using dangerouslySetInnerhtml, this clears scripts from enteries
import ProductPlaceholder from "../components/placeholders/ProductPlaceholder"
import NoImageSpirits from "../assets/images/products/No Image Spirits.png"

function ProductPage() {

  const { productID } = useParams()
  
  const [user, setUser] = useState({email: "", id: ""})
  const [product, setProduct] = useState({name: "", description: "", price: 0, image: ""})
  const [loading, setLoading] = useState([true, true])
  const [counter, setCounter] = useState(0)
  const [error, setError] = useState("")

  useEffect( () => {

    const fetchUser = async () => {
      const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/@me?type=")
      setUser(response.data)
      setLoading(loading[0] = false)
    }
    fetchUser()
      .catch( err => {
        setLoading(loading[0] = false)
        setError("unauthorized")
      })

    const fetchProduct = async () => {
      const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/product?productID=" + productID)
      setProduct(response.data)
      setLoading(loading[1] = false)
      }
      fetchProduct()
        .catch( err => {
          console.log(err.response.data)
          setLoading(loading[1] = false)
          setError("product not found")
        })
  }, [])

  const renderImage = () => {
    try {
      const image = product.direct_link ? product.direct_link : NoImageSpirits

      if(loading[1]) return <div className="image" ><ProductPlaceholder/></div>

      return <div className="image" ><img src={image} /></div>
    } catch (error) {
      return <div className="image" ><ProductPlaceholder/></div>
    }
  }

  return (
    <div className="body">
      {error == "unauthorized" ? window.location.href = "/login" : ""}

      <Header/>
    
      <div className="body-product-page">
        {renderImage()}
        {/* <div className="image"><img src= {"../img/products/" + product.image} /></div> */}
        <div className="title"><h1>{product.name}</h1></div>
        <AddToCartButton
          productID ={productID}
        />
        <div className="description" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(product.description)}}></div>
      </div>

      <Footer/>

   </div>
)}

export default ProductPage
