import React from "react"
import httpClient from "../httpClient"

function LogoutPage() {

  const logoutUser = async () => {
    await httpClient.post(process.env.REACT_APP_API_URL + "/api/logout")
    window.location.href = "/"
  }

  return (
   <div className="logout-btn">
      <svg 
        onClick={logoutUser} 
        className="logout-SVG" 
        preserveAspectRatio="xMidYMid meet" 
        viewBox="0 0 100 100"
        x="0" 
        xmlns="http://www.w3.org/2000/svg" 
        y="0"
      >
        <path 
          className="svg-stroke-primary" 
          d="M67.8,64.3,82.1,50m0,0L67.8,35.7M82.1,50H32.2M53.6,64.3v3.5A10.7,10.7,0,0,1,42.9,78.5H28.6A10.7,10.7,0,0,1,17.9,67.8V32.2A10.7,10.7,0,0,1,28.6,21.5H42.9A10.7,10.7,0,0,1,53.6,32.2v3.5" 
          fill="none" 
          strokeLinecap="round" 
          strokeLinejoin="round" 
          strokeWidth="8"
        >
        </path>
      </svg>
   </div>
   
)}

export default LogoutPage



